<template>
  <div>
    <div class="header" v-show="!signedIn">
      <img class="banner" src="/cd_banner.png" />
      <!-- <div>
        <img class="logo" src="../assets/logo.png" />
      </div> -->
    </div>
    <div class="hello">
      <!-- <h1>{{ msg }}</h1> -->
      <amplify-authenticator usernameAlias="email">
        <amplify-sign-up
          header-text="Direct Rewards App Sign Up"
          slot="sign-up"
          username-alias="email"
          :form-fields.prop="customSignUpFormFields"
        ></amplify-sign-up>
        <amplify-sign-in
          header-text="Direct Rewards App Login"
          slot="sign-in"
          username-alias="email"
        ></amplify-sign-in>
        <amplify-forgot-password
          header-text="Reset Direct Rewards App password"
          slot="forgot-password"
          username-alias="email"
        ></amplify-forgot-password>
        <!-- <amplify-confirm-sign-up
          header-text="Commerce Direct App Confirm Sign Up"
          slot="confirm-sign-up"
          username-alias="email"
        ></amplify-confirm-sign-up> -->
        <div>
          <!-- <amplify-sign-out></amplify-sign-out> -->
          Redirecting...
        </div>
      </amplify-authenticator>
    </div>
  </div>
</template>

<script>
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";

import VueRouter from "vue-router";

const router = new VueRouter({
  mode: "history",
});
export default {
  name: "App",
  created() {
    onAuthUIStateChange((nextAuthState, authData) => {
      if (nextAuthState === AuthState.SignedIn) {
        console.log("user successfully signed in!");

        let currentPath = window.location.pathname;

        if (currentPath === "/logout") {
          Auth.signOut({ global: true });
        }

        Auth.currentSession().then((res) => {
          let idToken = res.getIdToken();
          let accessToken = res.getAccessToken();
          let sub = idToken.payload.sub;

          if (currentPath !== "/logout") {
            router.push("token?idToken=" + idToken.jwtToken, () => {});
            window.location.href =
              "https://commercedirect.io/userlogin-success?user_id=" + sub + 
              "&id_token=" +
              idToken.jwtToken +
              "&access_token=" +
              accessToken.jwtToken;
            this.signedIn = true;
          }
        });
      }

      if (!authData) {
        console.log("user is not signed in...");
        this.signedIn = false;
        router.push("/", () => {});
      }
    });
  },
  data() {
    return {
      signedIn: false,
      customSignUpFormFields: [
        {
          type: "email",
          label: "Email Address *",
          placeholder: "Email",
          inputProps: { required: true, autocomplete: "email" },
        },
        {
          type: "password",
          label: "Password *",
          placeholder: "Password",
          inputProps: { required: true, autocomplete: "new-password" },
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
:root {
  /* Colors */
  --amplify-primary-color: #ea242a;
}

amplify-authenticator {
  position: absolute;
  top: 130px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.hello {
  text-align: center;
}
.logo {
  width: 100px;
  height: 100px;
}
.banner {
  width: 100%;
  height: auto;
}

.header {
  text-align: center;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  amplify-authenticator {
    top: 50px;
  }
}

@media screen and (max-width: 420px) {
  amplify-authenticator {
    top: 10px;
  }
}
</style>
