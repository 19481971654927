import Vue from 'vue'
import App from './components/HelloWorld.vue'
// import App from './App.vue'
import '@aws-amplify/ui-vue'
import Amplify, { Auth } from 'aws-amplify';
// Vue.use(AmplyfyPlugin,AmplifyModule);
Amplify.configure({
  Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-2',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-2_M3ch8l1yE',
      userPoolWebClientId: '4rr39avj39dfttvsr12mvm0muf'
  }
});

// You can get the current config object
Auth.configure();

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
